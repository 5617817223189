import { LanguageProvider } from "@/contexts/LanguageContext";
import "@/styles/globals.css";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
// import { QueryClient, QueryClientProvider } from "react-query";
import * as React from "react";

// const queryClient = new QueryClient();

const App = ({ Component, pageProps }: AppProps) => {
  return (
    // <QueryClientProvider client={queryClient}>
    <LanguageProvider>
      <Component {...pageProps} />
    </LanguageProvider>
    // </QueryClientProvider>
  );
};

export default appWithTranslation(App);
